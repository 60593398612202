
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Reverrals</h5>
      <div class="card">
        <div class="card-body">
          <div class="row gy-3 justify-content-between">
            <div class="col-12">
               <h5>Referral Link</h5>
               <p>{{referralLink}}</p>
            </div>
            <div class="col-12">
              <h5>Details</h5>
              <ul class="list">
                <li>10% commission on all payments</li>
                <li>30-day cookie</li>
               </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  data(){
      return {
      
      }
  },
  computed:{
    referralLink(){
      return this.absoluteUrl('/checkout/choose-a-plan/?via='+this.activeUser.reward_token)
    },
    activeUser(){
      return this.$store.state.AppActiveUser
    }
  }
}

</script>

<style lang="scss">

</style>
